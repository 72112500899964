<template>
  <div>
    <div v-if="!catalog" class="page-node">
      <TCPlayer v-if="play_url" v-bind:url="play_url"></TCPlayer>
      <p class="weui-btn-area">
        <a class="weui-btn weui-btn_primary" v-on:click="returnCatalog()">返回目录</a>
      </p>
    </div>
    <nav class="weui-navbar">
      <a class="weui-navbar__item" href="#page-1">课程</a>
      <a class="weui-navbar__item" href="#page-2">练习</a>
      <a class="weui-navbar__item" href="#page-3">测试卷</a>
    </nav>
    <div id="page-1">
      <br>
      <br>
      <div class="weui-grids">
        <a v-on:click="playVideo(c.url, '/course/')" class="weui-grid" v-for="c in course" :key="c.url">
          <p class="weui-grid__label">{{c.name}}</p>
        </a>
      </div>
    </div>
    <div id="page-2">
      <br>
      <br>
      <div class="weui-grids">
        <a v-on:click="playVideo(e1.url, '/exercises/')" class="weui-grid" v-for="e1 in exercises1" :key="e1.url">
          <p class="weui-grid__label">{{e1.name}}</p>
        </a>
        <a v-on:click="playVideo(e2.url, '/exercises/')" class="weui-grid" v-for="e2 in exercises2" :key="e2.url">
          <p class="weui-grid__label">{{e2.name}}</p>
        </a>
        <a v-on:click="playVideo(e3.url, '/exercises/')" class="weui-grid" v-for="e3 in exercises3" :key="e3.url">
          <p class="weui-grid__label">{{e3.name}}</p>
        </a>
      </div>
    </div>
    <div id="page-3">
      <br>
      <br>
      <div class="weui-grids">
        <a v-on:click="playVideo(t.url, '/test/')" class="weui-grid" v-for="t in test" :key="t.url">
          <p class="weui-grid__label">{{t.name}}</p>
        </a>
      </div>
    </div>
    <div class="weui-footer" style="padding: 10% 0;">
      <p class="weui-footer__text">Copyright ©福州市数酷教育科技有限公司</p>
    </div>
  </div>
</template>

<script>
  import TCPlayer from './TCPlayer.vue'
  import videoData from './VideoGrid.json'

  export default {
    components: {
      TCPlayer,
    },
    data() {
      return {
        play_url: '',
        catalog: true,
        course: videoData.course,
        exercises1: videoData.exercises,
        exercises2: [],
        exercises3: [],
        test: videoData.test,
      }
    },
    created() {
      let pathname = new URL(window.location.href).searchParams.get('url');
      if (pathname) {
        if (pathname.indexOf('course') !== -1) {
          for (let i = 0; i < this.course.length; i++) {
            if (pathname.indexOf(this.course[i].url + '.mp4') !== -1) {
              this.playVideo(this.course[i].url, '/course/');
              break;
            }
          }
        } else if (pathname.indexOf('exercises') !== -1) {
          for (let i = 0; i < this.exercises1.length; i++) {
            if (pathname.indexOf(this.exercises1[i].url + '.mp4') !== -1) {
              this.playVideo(this.exercises1[i].url, '/exercises/');
              break;
            }
          }
        } else if (pathname.indexOf('test') !== -1) {
          for (let i = 0; i < this.test.length; i++) {
            if (pathname.indexOf(this.test[i].url + '.mp4') !== -1) {
              this.playVideo(this.test[i].url, '/test/');
              break;
            }
          }
        }
      }
    },
    methods: {
      playVideo(e, path) {
        this.catalog = false;
        this.play_url = path + e + '.mp4';
      },
      returnCatalog() {
        this.catalog = true;
      }
    }
  }
</script>

<style>
  .weui-panel__hd::after {
    left: 0;
  }

  .weui-navbar {
    position: sticky;
    top: 4px;
    opacity: 0.9;
  }

  .weui-navbar::before,
  .weui-navbar::after {
    border: none;
  }

  .weui-navbar__item {
    background: #FFFFCC;
    color: black;
    box-shadow: 1px 1px 3px;
    border-radius: 5%;
  }

  .weui-navbar__item:nth-child(2) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .weui-navbar__item::after {
    border: none;
  }

  .weui-grid {
    width: 100%;
  }

  .weui-grid::before {
    border: none;
  }

  .weui-grid__label {
    text-align: left;
    text-indent: 1em;
  }

  .page-node {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: white;
    z-index: 999;
  }
</style>
