<template>
  <div id="wrap">
    <video id="player-container-id" playsinline webkit-playsinline></video>
  </div>
</template>

<script>
  import TCPlayer from 'tcplayer.js';
  import 'tcplayer.js/dist/tcplayer.min.css';

  export default {
    props:{
      url: {type: String}
    },
    data() {
      return {
        player: null
      }
    },
    watch: {
      url(v) {
        this.player.src('/video/' + v);
      }
    },
    mounted() {
      this.player = TCPlayer('player-container-id', {});
      this.player.src('/video/' + this.url);
    },
    destroyed() {
      this.player.dispose();
    }
  }
</script>

<style>
  #player-container-id {
    width: 100%;
    max-width: 100%;
    height: 0;
    padding-top: 56.25%;
  }
</style>
