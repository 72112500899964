<template>
  <div id="app">
    <VideoHome v-if="wait"></VideoHome>
    <VideoGrid v-else-if="is_vip"></VideoGrid>
    <VideoVip v-else></VideoVip>
  </div>
</template>

<script>
  import VideoHome from './components/VideoHome.vue'
  import VideoGrid from './components/VideoGrid.vue'
  import VideoVip from './components/VideoVip.vue'

  export default {
    name: 'App',
    components: {
      VideoHome,
      VideoGrid,
      VideoVip,
    },
    data() {
      return {
        wait: true,
        is_vip: false,
      }
    },
    created() {
      const url = new URL(window.location.href);
      if (url.searchParams.get('openid')) {
        sessionStorage.setItem('openid', url.searchParams.get('openid'));
      }
      
      let openid = sessionStorage.getItem('openid');
      if (openid) {
        let that = this;
        this.$http.get(process.env.VUE_APP_MICROSERVICE + '/video/vip?openid=' + openid).then((response) => {
          that.is_vip = response.data.is_vip;
          that.wait = false;
        });
      } else {
        let pathname = new URL(window.location.href).searchParams.get('url');
        window.location = process.env.VUE_APP_MICROSERVICE + '/oauth2?redirect_uri=' + encodeURIComponent(pathname);
      }
    },
  }
</script>

<style>
  body {
    padding: 0;
    margin: 0;
  }
</style>
