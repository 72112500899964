<template>
  <div class="container">
    <div v-if="!is_weixin" class="weui-information-bar weui-information-bar_warn-weak">
      <div class="weui-information-bar__hd">
        <i class="weui-mask-loading"></i>
      </div>
      &nbsp;
      <div class="weui-information-bar__bd">
        系统正在检测微信场景……
      </div>
    </div>
    <div v-if="ok" class="weui-toast">
      <i class="weui-icon-success-no-circle weui-icon_toast"></i>
      <p class="weui-toast__content">已完成</p>
    </div>
    <div v-if="!ok" class="weui-msg">
      <div style="margin-top: 1.5em; background-color: rgb(5,193,96); padding: 0 12%;"><img
          src="../assets/header.jpg" /></div>
      <div style="margin-top: 5em; padding: 0 13%;"><img src="../assets/introduction.jpg" /></div>
      <div style="margin-top: 4em; margin-bottom: 2em;">
        <span style="font-size: smaller;">原价: 299元</span>
        <span style="font-size: larger; color: rgb(250,114,52); padding: 1.4em; font-weight: bold;">{{desc}}</span>
      </div>
      <div style="padding: .5em 30%;"><img src="../assets/tip.jpg" /></div>
      <div v-if="is_weixin" class="weui-msg__opr-area">
        <p class="weui-btn-area">
          <button class="weui-btn weui-btn_primary" v-on:click="buy()">开通</button>
        </p>
      </div>
      <div style="margin-top: 4em; padding: 0 30%;"><img src="../assets/footer.jpg" /></div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        ok: false,
        is_weixin: false,
        desc: '',
      }
    },
    created() {
      this.$http.get(process.env.VUE_APP_MICROSERVICE + '/video/price')
      .then((response) => {
        this.desc = response.data.desc;
      });
      
      
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    methods: {
      onBridgeReady() {
        this.is_weixin = true;
      },
      buy() {
        let that = this
        this.$http.get(process.env.VUE_APP_MICROSERVICE + '/prepay?openid=' + sessionStorage.getItem('openid'))
        .then((response) => {
          WeixinJSBridge.invoke('getBrandWCPayRequest', response.data,
            function(res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                that.ok = true
              }
            });
        });
      }
    }
  }
</script>

<style>
  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    width: 100%;
  }
</style>
